var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.value.title))]), _c('v-card-subtitle', [_vm._v(_vm._s(_vm.value.description))]), _vm.startDate ? _c('v-card-text', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-clock-outline")]), _c('span', [_vm._v(_vm._s(_vm.startDate))]), _vm._v(" "), _vm.endDate ? _c('span', [_vm._v("- " + _vm._s(_vm.endDate))]) : _vm._e()], 1) : _vm._e(), _vm.value.address ? _c('v-card-text', [_c('copy-clipboard-text', {
    attrs: {
      "icon": "mdi-map-marker-outline",
      "text": _vm.addressText
    }
  })], 1) : _vm._e(), _vm.value.users ? _c('v-card-text', _vm._l(_vm.value.users, function (user, index) {
    return _c('v-list', {
      key: index
    }, [_c('v-list-item', [_vm._v(" " + _vm._s(user.email) + " (" + _vm._s(user.phone) + ")")])], 1);
  }), 1) : _vm._e()], 1), _vm.coordinates ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('partner-map', {
    staticClass: "z-index: 0; !important; ",
    attrs: {
      "coordinates": _vm.coordinates,
      "height": 265,
      "colour": "red"
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }