


















































































import RefsSelectionByType from "@/components/utility/RefsSelectionByType.vue";
import { HandoverStateEnum } from "@/lib/enum/handover-state.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Handover, IHandover } from "@/models/handover.entity";
import { IVehicle } from "@/models/vehicle.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import CompanyForm from "./CompanyForm.vue";
import Debug from "../utility/Debug.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({
  components: {
    ConfirmActionDialog,
    RefsSelectionByType,
    CompanyForm,
    Debug
  }
})
export default class HandoverCreateDialog extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  partnerId!: string;

  @Prop()
  vehicle?: IVehicle;

  get requiredRule() {
    return [requiredRule()];
  }

  isCreateLoding = false;

  isHandoverValid = false;
  keeperValid = false;
  ownerValid = false;
  newKeeperValid = false;

  get isValid() {
    return this.isHandoverValid && this.keeperValid && this.ownerValid && this.newKeeperValid;
  }

  isDialogActive = false;

  selectedVehicle: IVehicle | null = null;

  handoverMenu = false;

  handover: IHandover = new Handover(this._empty);

  private get _empty(): Partial<IHandover> {
    return {
      partnerId: this.partnerId,
      state: HandoverStateEnum.OPEN,
      vehicleId: this.vehicle?.id
    };
  }

  async openDialog() {
    if (this.vehicle) {
      this.setVehicle(this.vehicle);
    }

    this.isDialogActive = true;
    this.$nextTick(() => {
      (this.$refs.titleTextField as any)?.focus();
    });
  }

  setVehicle(selected: IVehicle) {
    this.selectedVehicle = selected;
    this.handover.vehicleId = selected.id;

    /**
     * TODO: if we have vehicle type for trains, add this here as well to the handover title
     * Add this magic btn unter the title
     */
    if (!this.handover.title) {
      this.handover.title = selected.displayName || "";
    }
    this.handoverMenu = false;
  }

  async create() {
    try {
      this.isCreateLoding = true;
      const created = await this.handover.create();
      this.$toast("👍");
      new GoToHelper(this.$router).goToHandoverDetail(created.id, created.partnerId, false);
    } catch (error) {
      handleError(error);
    } finally {
      this.isCreateLoding = false;
      this.isDialogActive = false;
    }
  }
}
