

























































import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import PartnerReportCardImages from "../partner/PartnerReportCardImages.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import MImageMarker, { IMarker } from "../utility/mmmint/MImageMarker.vue";

@Component({
  components: {
    MImageMarker,
    ConfirmActionDialog,
    PartnerReportCardImages,
    Debug
  }
})
export default class CreateHandoverFindingDialog extends Vue {
  @Prop({ default: false })
  isDialogActive!: boolean;

  report = new CreateReportAsPartnerDto();

  step = 1;

  example = AssetEnum.damageDetail;

  get fullScreen() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get type() {
    return ReportImageType.damageDetail;
  }

  imageMarkerSrc = "https://static.mmmint.ai/public-partner/2024-08-01-header-3a93add1cd77a4980762124d.jpg";

  markers: IMarker[] = [];

  get image() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.bookingSuccess);
  }

  /**
   * Image options for selection
   * should come frome vehicle blueprints
   */
  imageOptions = [
    {
      imageId: 1,
      text: "Image 1",
      src: "https://static.mmmint.ai/public-partner/2024-08-01-header-3a93add1cd77a4980762124d.jpg"
    },
    {
      imageId: 2,
      text: "Image 2",
      src: "https://www.schadensmeldung.digital/images/features/schadenmanagement/individueller-funnel.webp"
    }
  ];

  get hideLeft() {
    if (this.step === 1) {
      return true;
    }
    return false;
  }

  get leftText() {
    if (this.step === 4) {
      return "Befund ansehen";
    }
    return "Zurück";
  }

  get rightText() {
    if (this.step === 3) {
      return "Befund anlegen";
    }
    if (this.step === 4) {
      return "Weiteren Befund anlegen";
    }
    return "Weiter";
  }

  get title() {
    switch (this.step) {
      case 1:
        return "Wo ist der Befund?";
      case 2:
        return "Fotodukumentation";
      case 3:
        return "Details";
      case 4:
        return "Befund angelegt";
      default:
        return "";
    }
  }

  async rightClick() {
    this.step = this.step + 1;
    if (this.step === 4) {
      await this.report.create(PartnerModule.partner._id || PartnerModule.partner.id);
      this.report = new CreateReportAsPartnerDto();

      this.$toast("Befund erfolgreich angelegt");
    }
  }

  async leftClick() {
    this.step = this.step - 1;
  }

  addMarker(marker: IMarker) {
    marker.text = (this.markers.length + 1).toString();
    this.markers = [marker];
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.$emit("update:isDialogActive", v);
  }

  close() {
    this.$emit("close");
    this.dialogModel = false;
  }
}
