var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._t("activator", null, {
    "on": _vm.openDialog
  }), _vm.isDialogActive ? _c('confirm-action-dialog', {
    attrs: {
      "title": 'new inspection',
      "isDialogActive": _vm.isDialogActive,
      "rightLoading": _vm.isCreateLoading,
      "rightDisabled": !_vm.isValid,
      "width": "1050"
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "rightClick": _vm.create
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isInspectionValid,
      callback: function callback($$v) {
        _vm.isInspectionValid = $$v;
      },
      expression: "isInspectionValid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-text-field', {
    ref: "titleTextField",
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "label": _vm.$t('objects.inspection.title')
    },
    model: {
      value: _vm.inspection.title,
      callback: function callback($$v) {
        _vm.$set(_vm.inspection, "title", $$v);
      },
      expression: "inspection.title"
    }
  }), _c('v-textarea', {
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "label": _vm.$t('objects.inspection.description')
    },
    model: {
      value: _vm.inspection.description,
      callback: function callback($$v) {
        _vm.$set(_vm.inspection, "description", $$v);
      },
      expression: "inspection.description"
    }
  }), _c('custom-field-date-time', {
    attrs: {
      "customField": {
        label: _vm.$t('objects.inspection.start')
      }
    },
    model: {
      value: _vm.inspection.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.inspection, "startDate", $$v);
      },
      expression: "inspection.startDate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('address-form', {
    attrs: {
      "isValid": _vm.isAddressValid,
      "subheader": _vm.$t('objects.inspection.address')
    },
    on: {
      "update:isValid": function updateIsValid($event) {
        _vm.isAddressValid = $event;
      },
      "update:is-valid": function updateIsValid($event) {
        _vm.isAddressValid = $event;
      }
    },
    model: {
      value: _vm.inspection.address,
      callback: function callback($$v) {
        _vm.$set(_vm.inspection, "address", $$v);
      },
      expression: "inspection.address"
    }
  }), _vm._v(" CompanyForm inspection.users "), _c('br'), _vm._v(" inspection.address ")], 1)], 1)], 1)], 1) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }