


























import { detailedDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IInspection } from "@/models/inspection.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { VSkeletonLoader } from "vuetify/lib";
import CopyClipboardText from "../utility/CopyClipboardText.vue";

const PartnerMap = () => ({
  component: import(/* webpackPrefetch: true  */ /* webpackChunkName: "map" */ "@/components/form/PartnerMap.vue"),
  loading: VSkeletonLoader
});

@Component({
  components: { PartnerMap, CopyClipboardText },
  filters: { detailedDate }
})
export default class InspectionCard extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  value!: IInspection;

  get isMail() {
    return !!this.value.users;
  }

  get mails() {
    return this.value.users.map(u => u.email).join("; ");
  }

  get startDate() {
    if (!this.value.start) {
      return "";
    }

    return detailedDate(new Date(this.value.start).toString());
  }

  get endDate() {
    if (!this.value.end) {
      return "";
    }

    return detailedDate(new Date(this.value.end).toString());
  }

  get coordinates() {
    return this.value.address?.geo || undefined;
  }

  get addressText() {
    return `${this.value.address.street}, ${this.value.address.zip} ${this.value.address.city}`;
  }
}
