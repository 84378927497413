













import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import MHeader, { IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import { dottedSubString } from "@/lib/utility/string-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Handover, IHandover } from "@/models/handover.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { HandoverReportModule } from "@/store/modules/handover-report.store";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { TheLayoutPortal, MHeader, PaginatedTable, LatestEntriesCardEmpty }
})
export default class HandoverReportTableView extends mixins(Vue, PermissionMixin) {
  title = this.$t("handover.report.title");

  readonly handoverReportStore = HandoverReportModule;

  get partnerId() {
    return this.handover?.partnerId;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.handover) {
      return breadcrumbs;
    }

    breadcrumbs.push({
      text: this.$t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.handover.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.handover.title, 30);
    } else {
      handoverTitle = `#${this.handover.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: false,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    breadcrumbs.push({
      text: this.$t("handover.report.title"),
      exact: true,
      disabled: true,
      to: {
        name: "HandoverReportTableView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    return breadcrumbs;
  }

  get headers() {
    return [
      {
        text: "Created",
        value: "timestamp.created",
        width: 100
      },
      {
        text: "Title",
        value: "title",
        width: 90
      },
      {
        text: "Message",
        value: "message",
        width: 90
      }
    ];
  }

  isLoading = false;

  handover: IHandover | null = null;

  vehicle: IVehicle | null = null;

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;
  }

  async fetchVehicle() {
    const partnerId = this.$route.params.partnerId;
    const vehicleId = this.handover?.vehicleId;

    const vehicle = await new Vehicle({
      partnerId: partnerId,
      id: vehicleId
    }).fetch();

    this.vehicle = vehicle;
  }

  async mounted() {
    try {
      this.isLoading = true;

      await this.fetchHandover();

      HandoverReportModule.setHiddenFilters([
        new PageFilterElement({ key: "handover.handoverId", value: this.$route.params.handoverId })
      ]);

      await Promise.all([this.fetchVehicle()]);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
