































































































































import RefsVehicle from "@/components/utility/RefsVehicle.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { dottedSubString } from "@/lib/utility/string-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IHandover } from "@/models/handover.entity";
import { IVehicle } from "@/models/vehicle.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { HandoverReportModule } from "@/store/modules/handover-report.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import ActivityCard from "../thg/ActivityCard.vue";
import Debug from "../utility/Debug.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailTable, {
  DetailTableTypeEnum,
  DetailTableVisualizationEnum,
  IMDetailTableConfig
} from "../utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IBreadcrumb, IChips } from "../utility/mmmint/MHeader.vue";
import MImageMarker from "../utility/mmmint/MImageMarker.vue";
import MNavigationList from "../utility/mmmint/MNavigationList.vue";
import MQuickLinkList from "../utility/mmmint/MQuickLinkList.vue";
import RefsList from "../utility/RefsList.vue";
import CreateHandoverFindingDialog from "./CreateHandoverFindingDialog.vue";
import InspectionCard from "./InspectionCard.vue";
import TourHandoverFindingDialog from "./TourHandoverFindingDialog.vue";

@Component({
  components: {
    Debug,
    MHeader,
    MActionList,
    MDetailViewGrid,
    MDetailTable,
    MImageMarker,
    MNavigationList,
    MQuickLinkList,
    CreateHandoverFindingDialog,
    TourHandoverFindingDialog,
    InspectionCard,
    RefsList,
    RefsVehicle,
    PaginatedTable,
    ActivityCard,
    LatestEntriesCardEmpty
  }
})
export default class HandoverDetail extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  value!: IHandover;

  @Prop()
  vehicle!: IVehicle;

  readonly handoverReportStore = HandoverReportModule;

  createDialog = false;

  tourDialog = false;

  get partnerId() {
    return this.value.partnerId;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get title() {
    if (!this.value) {
      return this.$t("common.handover");
    }

    return `#${this.value.number} ${this.value.title}`;
  }

  get subtitle() {
    return "";
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.value) {
      return breadcrumbs;
    }

    breadcrumbs.push({
      text: this.$t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.value.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.value.title, 30);
    } else {
      handoverTitle = `#${this.value.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: true,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.value.partnerId,
          handoverId: this.value.id
        }
      }
    });

    return breadcrumbs;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    actions.push(
      {
        text: "Schaden erstellen",
        key: "primaryAction",
        icon: "mdi-wrench-outline",
        exec: () => (this.createDialog = true)
      },
      {
        text: "Rundgang starten",
        key: "secondaryAction",
        icon: "mdi-ray-start-arrow",
        exec: () => (this.tourDialog = true)
      },
      {
        text: "Begehung erstellen",
        key: "showDetails",
        icon: "mdi-calendar-clock"
      },
      {
        text: "Protokoll erstellen",
        key: "showDetails",
        icon: "mdi-note-edit-outline"
      },
      {
        text: "Übergabe löschen",
        key: "fourthAction",
        icon: "mdi-delete-outline",
        color: "red"
      }
    );

    return actions;
  }

  get chips(): IChips[] {
    const chips: IChips[] = [];

    chips.push({
      text: this.vehicle.displayName ?? this.vehicle.identificationnumber ?? "",
      key: "vehicle",
      exec: () => this.goToVehicle(this.vehicle)
    });

    return chips;
  }

  get tableShowMoreAction() {
    // return {
    //   text: $t("designGuide.MDetailForm.allProps"),
    //   key: "showDetails"
    // };

    return undefined;
  }

  get navigation() {
    return [
      {
        text: "Befunde",
        key: "primaryAction",
        icon: "mdi-magnify",
        color: "success",
        count: this.handoverReportStore._pageProvider.totalItems,
        exec: this.goToHandoverReports
      },
      {
        text: "Begehungen",
        key: "secondAction",
        icon: "mdi-note-edit-outline",
        color: "warning",
        count: this.value.inspections.length,
        exec: this.goToInspections
      },
      {
        text: "Protokolle",
        key: "showDetails",
        icon: "mdi-code-tags",
        color: "info",
        count: this.value.snapshots.length
      },
      {
        text: "Aktivitäten",
        key: "showActivities",
        icon: "mdi-timeline-check-outline",
        color: "black"
      },
      {
        text: "Analysen",
        key: "fourthAction",
        icon: "mdi-chart-line",
        color: "grey"
      }
    ];
  }

  get quickLinks() {
    return [
      {
        text: "Neuer Befund",
        description: "Dokumentieren Sie Schäden und Befunde am Fahrzeug",
        key: "newFinding",
        color: "success",
        exec: () => (this.createDialog = true)
      },
      {
        text: "Rundgang starten",
        description: "Starten Sie einen Rundgang um alle Befunde zu begutachten.",
        key: "startTour",
        color: "success",
        exec: () => (this.tourDialog = true)
      },
      {
        text: "Begehung erstellen",
        description: "Legen Sie eine Begehung mit Ort, Datum, Teilnehmenden und mehr an.",
        key: "newInspection",
        color: "warning"
      },
      {
        text: "Protokoll erstellen",
        description: "Erstellen Sie ein revisionssicheres Protokoll des Status Quo.",
        key: "newProtocoll",
        color: "info"
      }
    ];
  }

  get detailTableConfig(): IMDetailTableConfig[] {
    return [
      {
        key: "state",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [
          { value: "open", displayValue: "Offen", color: "success" },
          { value: "closed", displayValue: "Kunde", color: "grey" }
        ]
      },
      {
        key: "description",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "owner",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      },
      {
        key: "keeper",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      },
      {
        key: "newKeeper",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      },
      {
        key: "timestamp.created",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.DATE
      }
    ];
  }

  get activitySource(): MrfiktivReferenceGen | undefined {
    if (!this.value) {
      return undefined;
    }

    return {
      refType: this.ResourceEnum.HANDOVER,
      refId: this.value.id
    };
  }

  get headers() {
    return [
      {
        text: "Created",
        value: "timestamp.created",
        width: 100
      },
      {
        text: "Title",
        value: "title",
        width: 90
      },
      {
        text: "Message",
        value: "message",
        width: 90
      }
    ];
  }

  get partner() {
    return PartnerModule.partner;
  }

  get isMail() {
    return !!this.value.users;
  }

  get mails() {
    return this.value.users.map(u => u.email).join("; ");
  }

  goToHandoverReports() {
    new GoToHelper(this.$router).goToHandoverReportTableView(this.value.id, this.value.partnerId, false);
  }

  goToInspections() {
    new GoToHelper(this.$router).goToInspectionTableView(this.value.id, this.value.partnerId, false);
  }

  goToInspection(id: string) {
    new GoToHelper(this.$router).goToInspectionDetail(id, this.value.id, this.value.partnerId, false);
  }

  goToVehicle(value: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(value.id, value.partnerId, VehicleTabs.HOME, true);
  }
}
