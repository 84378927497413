





































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import { HandoverModule } from "@/store/modules/handover.store";
import { PartnerModule } from "@/store/modules/partner";
import { IHandover } from "@/models/handover.entity";
import HandoverCreateDialog from "./HandoverCreateDialog.vue";
import { $t } from "@/lib/utility/t";
import RefsVehicle from "../utility/RefsVehicle.vue";
import RefsList from "../utility/RefsList.vue";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";

@Component({
  components: {
    PaginatedTable,
    HandoverCreateDialog,
    RefsVehicle,
    RefsList
  }
})
export default class HandoverTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop({})
  vehicle?: IVehicle;

  @Prop({ default: false })
  newTab!: boolean;

  @Prop({ default: false })
  newTabVehicle!: boolean;

  readonly store = HandoverModule;

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.handover.number"),
      value: "number",
      sortable: false,
      width: "60px"
    });

    headers.push({
      text: $t("objects.handover.title"),
      value: "title",
      sortable: false
    });

    headers.push({
      text: $t("objects.handover.state"),
      value: "state",
      sortable: false
    });

    if (!this.vehicle) {
      headers.push({
        text: $t("objects.handover.vehicleId"),
        value: "vehicleId",
        sortable: false
      });
    }

    return headers;
  }

  get predefinedFilter(): { name: string; filter: IPageFilterElement[] }[] {
    return [];
  }

  beforeMount() {
    if (this.vehicle) {
      this.store.setFilters([
        new PageFilterElement({
          key: "vehicleId",
          value: this.vehicle.id
        })
      ]);
    }
  }

  setSelectedItem(item: IHandover) {
    new GoToHelper(this.$router).goToHandoverDetail(item.id, item.partnerId, this.newTab);
  }

  async getVehicle(vehicleId: string) {
    let vehicle = VehicleModule.maps.id.get(vehicleId)[0];
    if (vehicle) return vehicle;
    vehicle = await new Vehicle({ id: vehicleId, partnerId: this.partnerId }).fetch();

    return vehicle;
  }

  goToVehicle(value: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(value.id, value.partnerId, VehicleTabs.HOME, this.newTabVehicle);
  }
}
