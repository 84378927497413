
























import { Component, Prop, Vue } from "vue-property-decorator";
import { IAction } from "./MHeader.vue";

@Component({})
export default class MQuickLinkList extends Vue {
  @Prop()
  actions!: IAction[];

  @Prop({ default: "" })
  title!: string;

  get activeActions() {
    return this.actions;
  }

  actionClicked(action: IAction | undefined) {
    if (action) {
      this.$emit("actionClicked", action);
    }
  }
}
