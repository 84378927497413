import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IReport, Report } from "@/models/report.entity";
import reportService from "@/services/mrfiktiv/services/reportService";
import { MrfiktivReportControllerFindAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { ProgressStatusEnum } from "../enum/partner/progress.status.enum";
import { PaginatedBaseStore } from "../paginated-base.store";
import { PaginationFilterListElement } from "./base-pagination.store";

export const HandoverReportDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<
  IReport,
  "assignees" | "vehicleId"
> {
  protected mapEntity(entity: IReport): IReport {
    return new Report(entity);
  }

  getIdentifier(entity: IReport): string {
    return entity.id;
  }

  mapsConfig = {
    assignees: {
      getIdentifier: (entity: IReport) => entity.assignees
    },
    vehicleId: {
      getIdentifier: (entity: IReport) => (entity.vehicleId ? [entity.vehicleId] : [])
    }
  };
})();

export class HandoverReportPageDataProvider extends AbstractPageDataProvider<
  IReport,
  MrfiktivReportControllerFindAllParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivReportControllerFindAllParamsGen): Promise<IPageViewModel<IReport>> {
    const res = await reportService.getReportPaginated(query);

    const meta = res.meta;
    const data = (res.data ?? []) as IReport[];

    return { meta, data };
  }
}
/**
 * Store for admins
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "handoverReports",
  store
})
export class HandoverReportStore extends PaginatedBaseStore<IReport, MrfiktivReportControllerFindAllParamsGen> {
  _data = HandoverReportDataAccessLayer;
  _pageProvider = new HandoverReportPageDataProvider();
  _pager = new PageDataHandler(this._data, this._pageProvider);

  /**
   * Filters, ordered by most used
   */
  filterOptions: PaginationFilterListElement[] = [
    { key: "_id", type: PageFilterTypes.OBJECT_ID },
    { key: "numberplate", type: PageFilterTypes.STRING },
    { key: "status", type: PageFilterTypes.ENUM, config: { items: Object.values(ProgressStatusEnum) } }
  ].map(f => new PaginationFilterListElement(f));
}

export const HandoverReportModule = getModule(HandoverReportStore);
