import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import { AddressWithGeo, IAddressWithGeo } from "@/models/address.entity";
import { Contact, IContact } from "@/models/contact.entity";
import inspectionService from "@/services/mrfiktiv/services/inspectionService";
import {
  MrfiktivCreateInspectionDtoGen,
  MrfiktivInspectionViewModelGen,
  MrfiktivUpdateInspectionDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { ITransactionData, TransactionData } from "./transaction-data.entity";

@IsFilterable
class InspectionBase implements MrfiktivInspectionViewModelGen, ICreateDto<IInspection> {
  /**
   * @inheritdoc
   */
  @FilterConfig({ type: FilterTypes.STRING })
  id: string;

  handoverId: string;

  partnerId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({ displayName: "objects.inspection.title", type: FilterTypes.STRING })
  title: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({ displayName: "objects.inspection.description", type: FilterTypes.STRING })
  description: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({ displayName: "objects.inspection.users", type: Contact })
  users: IContact[];

  /**
   * @inheritdoc
   */
  @FilterConfig({ displayName: "objects.inspection.address", type: AddressWithGeo })
  address: IAddressWithGeo;

  /**
   * @inheritdoc
   */
  start?: number;

  private startString = "";

  @FilterConfig({ displayName: "objects.inspection.start", type: FilterTypes.DATE })
  get startDate() {
    if (!this.start) return undefined;

    const date = new Date(this.start);
    const yyyy = `${date.getFullYear()}`.padStart(4, "0");
    const mm = `${date.getMonth() + 1}`.padStart(2, "0");
    const dd = `${date.getDate()}`.padStart(2, "0");
    const hh = `${date.getHours()}`.padStart(2, "0");
    const min = `${date.getMinutes()}`.padStart(2, "0");

    return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
  }

  set startDate(date: string | undefined) {
    if (!date) this.start = undefined;
    else this.start = new Date(date).getTime();
  }

  /**
   * @inheritdoc
   */
  end?: number;

  @FilterConfig({ displayName: "objects.inspection.end", type: FilterTypes.DATE })
  get endDate() {
    if (!this.end) return undefined;

    const date = new Date(this.end);
    const yyyy = `${date.getFullYear()}`.padStart(4, "0");
    const mm = `${date.getMonth() + 1}`.padStart(2, "0");
    const dd = `${date.getDate()}`.padStart(2, "0");
    const hh = `${date.getHours()}`.padStart(2, "0");
    const min = `${date.getMinutes()}`.padStart(2, "0");

    return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
  }

  set endDate(date: string | undefined) {
    if (!date) this.end = undefined;
    else this.end = new Date(date).getTime();
  }

  /**
   * @inheritdoc
   */
  @FilterConfig({ type: TransactionData })
  data: ITransactionData;

  /**
   * @inheritdoc
   */
  @FilterConfig({ displayName: "objects.inspection.timestamp", type: Timestamp })
  timestamp: ITimestamp;

  /**
   * Construct handover
   */
  constructor(inspection?: Partial<MrfiktivInspectionViewModelGen & { handoverId: string; partnerId: string }>) {
    this.id = inspection?.id ?? "";
    this.handoverId = inspection?.handoverId ?? "";
    this.partnerId = inspection?.partnerId ?? "";

    this.title = inspection?.title ?? "";
    this.description = inspection?.title ?? "";
    this.users = (inspection?.users ?? []).map(i => new Contact(i));
    this.address = new AddressWithGeo(inspection?.address);
    this.start = inspection?.start;
    this.end = inspection?.end;
    this.data = new TransactionData(inspection?.data);

    this.timestamp = new Timestamp(inspection?.timestamp);
  }

  /**
   * fetch handover
   */
  async fetch(): Promise<this> {
    const res = await inspectionService.findOne(this.partnerId, this.handoverId, this.id);

    this.map(res);

    return this;
  }

  /**
   * map props from viewmodel to this
   */
  private map(inspection?: MrfiktivInspectionViewModelGen) {
    if (!inspection) return;
    this.id = inspection.id ?? "";
    this.title = inspection?.title ?? "";
    this.description = inspection.description ?? "";
    this.users = (inspection.users ?? []).map(i => new Contact(i));
    this.address = new AddressWithGeo(inspection.address);
    this.start = inspection.start;
    this.end = inspection.end;
    this.data = new TransactionData(inspection?.data);
    this.timestamp = new Timestamp(inspection.timestamp);
  }

  /**
   * create fetch handover
   */
  async create() {
    const data: MrfiktivCreateInspectionDtoGen = {
      title: this.title,
      description: this.description,
      users: this.users,
      address: this.address,
      start: this.start,
      data: this.data,
      end: this.end
    };
    const res = await inspectionService.create(this.partnerId, this.handoverId, data);

    this.map(res);

    return this;
  }

  /**
   * delete handover
   */
  async delete() {
    const res = await inspectionService.remove(this.partnerId, this.handoverId, this.id);

    this.map(res);
  }

  /**
   * update handover
   * @returns
   */
  async update() {
    const data: MrfiktivUpdateInspectionDtoGen = {
      title: this.title,
      description: this.description,
      users: this.users,
      address: this.address,
      start: this.start,
      end: this.end,
      data: this.data
    };
    const res = await inspectionService.update(this.partnerId, this.handoverId, this.id, data);
    this.map(res);

    return this;
  }

  /**
   * update handover via dto
   * @param dto
   * @returns
   */
  async updatePartial(dto: MrfiktivUpdateInspectionDtoGen) {
    const res = await inspectionService.update(this.partnerId, this.handoverId, this.id, dto);

    this.map(res);

    return this;
  }
}

type IInspection = InspectionBase;
const Inspection = Filter.createForClass(InspectionBase);

export { IInspection, Inspection };
