import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { MrfiktivCompanyDtoGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFilterable
class CompanyBase implements MrfiktivCompanyDtoGen {
  @FilterConfig({ displayName: "objects.company.companyName", type: FilterTypes.STRING })
  companyName: string;

  @FilterConfig({ displayName: "objects.company.email", type: FilterTypes.STRING })
  email: string;

  @FilterConfig({ displayName: "objects.company.phone", type: FilterTypes.STRING })
  phone: string;

  constructor(contact?: Partial<MrfiktivCompanyDtoGen>) {
    this.companyName = contact?.companyName ?? "";
    this.email = contact?.email ?? "";
    this.phone = contact?.phone ?? "";
  }
}

type ICompany = CompanyBase;
const Company = Filter.createForClass(CompanyBase);

export { Company, ICompany };
