var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._t("activator", null, {
    "on": _vm.openDialog
  }), _c('confirm-action-dialog', {
    attrs: {
      "title": 'new handover',
      "isDialogActive": _vm.isDialogActive,
      "rightLoading": _vm.isCreateLoding,
      "rightDisabled": !_vm.isValid,
      "width": "1050"
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "rightClick": _vm.create
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isHandoverValid,
      callback: function callback($$v) {
        _vm.isHandoverValid = $$v;
      },
      expression: "isHandoverValid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-text-field', {
    ref: "titleTextField",
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "label": _vm.$t('objects.handover.title')
    },
    model: {
      value: _vm.handover.title,
      callback: function callback($$v) {
        _vm.$set(_vm.handover, "title", $$v);
      },
      expression: "handover.title"
    }
  }), _c('v-textarea', {
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "label": _vm.$t('objects.handover.description')
    },
    model: {
      value: _vm.handover.description,
      callback: function callback($$v) {
        _vm.$set(_vm.handover, "description", $$v);
      },
      expression: "handover.description"
    }
  }), _c('company-form', {
    attrs: {
      "isValid": _vm.keeperValid,
      "subheader": _vm.$t('objects.handover.keeper')
    },
    on: {
      "update:isValid": function updateIsValid($event) {
        _vm.keeperValid = $event;
      },
      "update:is-valid": function updateIsValid($event) {
        _vm.keeperValid = $event;
      }
    },
    model: {
      value: _vm.handover.keeper,
      callback: function callback($$v) {
        _vm.$set(_vm.handover, "keeper", $$v);
      },
      expression: "handover.keeper"
    }
  }), _c('company-form', {
    attrs: {
      "isValid": _vm.ownerValid,
      "subheader": _vm.$t('objects.handover.owner')
    },
    on: {
      "update:isValid": function updateIsValid($event) {
        _vm.ownerValid = $event;
      },
      "update:is-valid": function updateIsValid($event) {
        _vm.ownerValid = $event;
      }
    },
    model: {
      value: _vm.handover.owner,
      callback: function callback($$v) {
        _vm.$set(_vm.handover, "owner", $$v);
      },
      expression: "handover.owner"
    }
  }), _c('company-form', {
    attrs: {
      "isValid": _vm.newKeeperValid,
      "subheader": _vm.$t('objects.handover.newKeeper')
    },
    on: {
      "update:isValid": function updateIsValid($event) {
        _vm.newKeeperValid = $event;
      },
      "update:is-valid": function updateIsValid($event) {
        _vm.newKeeperValid = $event;
      }
    },
    model: {
      value: _vm.handover.newKeeper,
      callback: function callback($$v) {
        _vm.$set(_vm.handover, "newKeeper", $$v);
      },
      expression: "handover.newKeeper"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [!_vm.selectedVehicle ? _c('v-text-field', {
    attrs: {
      "value": '',
      "disabled": "",
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.handover.vehicleId'),
      "readonly": "",
      "outlined": ""
    }
  }) : _c('v-text-field', {
    attrs: {
      "value": _vm.selectedVehicle.displayName,
      "disabled": "",
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.handover.vehicleId'),
      "readonly": "",
      "outlined": ""
    }
  }), _c('refs-selection-by-type', {
    staticClass: "ma-1",
    attrs: {
      "partnerId": _vm.partnerId,
      "refType": _vm.ResourceEnum.VEHICLE,
      "showAsMenu": false,
      "selected": _vm.handover.vehicleId ? [{
        refId: _vm.handover.vehicleId,
        refType: _vm.ResourceEnum.VEHICLE
      }] : []
    },
    on: {
      "select": _vm.setVehicle
    }
  }), _c('debug', [_vm._v(" " + _vm._s(_vm.handover) + " ")])], 1)], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }