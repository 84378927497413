

















import ActivityCard from "@/components/thg/ActivityCard.vue";
import { detailedDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IHandover } from "@/models/handover.entity";
import { IInspection } from "@/models/inspection.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import MHeader from "../utility/mmmint/MHeader.vue";
import InspectionCard from "./InspectionCard.vue";

@Component({
  components: { Debug, InspectionCard, MHeader, ActivityCard },
  filters: { detailedDate }
})
export default class InspectionDetail extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  value!: IInspection;

  @Prop()
  handover!: IHandover;

  get isMail() {
    return !!this.value.users;
  }

  get mails() {
    return this.value.users.map(u => u.email).join("; ");
  }

  get startDate() {
    if (!this.value.start) {
      return "";
    }

    return detailedDate(new Date(this.value.start).toString());
  }

  get endDate() {
    if (!this.value.end) {
      return "";
    }

    return detailedDate(new Date(this.value.end).toString());
  }

  get coordinates() {
    return this.value.address?.geo || undefined;
  }

  get addressText() {
    return `${this.value.address.street}, ${this.value.address.zip} ${this.value.address.city}`;
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get activitySource(): MrfiktivReferenceGen | undefined {
    if (!this.value) {
      return undefined;
    }

    return {
      refType: this.ResourceEnum.HANDOVER,
      refId: this.value.id
    };
  }
}
