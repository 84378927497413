var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('debug', [_vm._v("value " + _vm._s(_vm.value))]), _c('inspection-card', {
    attrs: {
      "value": _vm.value
    }
  }), _vm.activitySource ? _c('activity-card', {
    key: 'activity-card' + _vm.partnerId,
    attrs: {
      "partner": _vm.partner,
      "user": _vm.value,
      "source": _vm.activitySource,
      "config": {
        comment: true,
        email: _vm.isMail
      },
      "mail": _vm.mails
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }