var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.dialogModel,
      "absolute": true,
      "leftText": _vm.leftText,
      "rightText": _vm.rightText,
      "hideLeft": _vm.hideLeft,
      "title": _vm.title,
      "width": "1200px",
      "persistent": true,
      "fullscreen": _vm.fullScreen
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialogModel = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialogModel = $event;
      },
      "close": _vm.close,
      "rightClick": _vm.rightClick,
      "leftClick": _vm.leftClick
    }
  }, [_c('v-window', {
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-window-item', {
    attrs: {
      "value": 1
    }
  }, [_c('v-card-text', [_vm._v(" Wählen Sie zunächst den Bereich aus und anschließend die genaue Position. Tipp: Wenn Sie den gedrückt halten, erscheint eine Lupe, damit Sie die Lokalisierung noch genauer vornehmen können. ")]), _c('v-select', {
    staticClass: "mx-4",
    attrs: {
      "items": _vm.imageOptions,
      "item-text": "text",
      "item-value": "src",
      "outlined": ""
    },
    model: {
      value: _vm.imageMarkerSrc,
      callback: function callback($$v) {
        _vm.imageMarkerSrc = $$v;
      },
      expression: "imageMarkerSrc"
    }
  }), _c('m-image-marker', {
    ref: "imageMarkerCreateDialog",
    staticClass: "mx-4",
    attrs: {
      "src": _vm.imageMarkerSrc,
      "markers": _vm.markers
    },
    on: {
      "markerCreated": _vm.addMarker
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": 2
    }
  }, [_c('v-card-text', [_vm._v(" Erstellen Sie aussagekräftige Fotos vom Befund. ")]), _c('partner-report-card-images', {
    staticClass: "ma-1",
    attrs: {
      "dto": _vm.report
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.report = $event;
      }
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": 3
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "Titel"
    }
  }), _c('v-textarea', {
    attrs: {
      "outlined": "",
      "label": "Beschreibung"
    },
    model: {
      value: _vm.report.message,
      callback: function callback($$v) {
        _vm.$set(_vm.report, "message", $$v);
      },
      expression: "report.message"
    }
  }), _c('v-text-field', {
    attrs: {
      "value": "Welche Attribute müssen erfasst werden?",
      "outlined": "",
      "label": "Attribut"
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": 4
    }
  }, [_c('v-img', {
    staticClass: "mt-8",
    attrs: {
      "contain": "",
      "src": _vm.image,
      "height": 150,
      "justify": "center"
    }
  }), _c('v-card-text', {
    staticClass: "text-center"
  }, [_vm._v("Befund erfoglreich angelegt")])], 1)], 1), _c('debug', [_vm._v(_vm._s(_vm.report))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }