import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { MrfiktivCreateInspectionDtoGen, MrfiktivUpdateInspectionDtoGen } from "../v1/data-contracts";
import { Handover } from "../v1/Handover";

/**
 * Communicate with inspection endpoints
 */
class InspectionService {
  mrfiktivProxy: Handover;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Handover(mrfiktivHttpClientProvider.client());
  }

  async create(partnerId: string, handoverId: string, data: MrfiktivCreateInspectionDtoGen) {
    return (await this.mrfiktivProxy.inspectionControllerCreate(partnerId, handoverId, data)).data;
  }

  async findAll(partnerId: string, handoverId: string) {
    return (await this.mrfiktivProxy.inspectionControllerFindAll(partnerId, handoverId)).data;
  }

  async findOne(partnerId: string, handoverId: string, inspectionId: string) {
    return (await this.mrfiktivProxy.inspectionControllerFindOne(partnerId, handoverId, inspectionId)).data;
  }

  async update(partnerId: string, handoverId: string, inspectionId: string, data: MrfiktivUpdateInspectionDtoGen) {
    return (await this.mrfiktivProxy.inspectionControllerUpdate(partnerId, handoverId, inspectionId, data)).data;
  }

  async remove(partnerId: string, handoverId: string, inspectionId: string) {
    return (await this.mrfiktivProxy.inspectionControllerRemove(partnerId, handoverId, inspectionId)).data;
  }
}

/**
 * Communicate with inspection endpoints
 */
export default new InspectionService(new MrfiktivHttpClientProvider());
