var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('m-header', {
    attrs: {
      "title": _vm.title,
      "subtitle": _vm.subtitle,
      "chips": _vm.chips,
      "breadCrumbs": _vm.breadCrumbs,
      "actions": _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      }
    }
  }), _c('m-detail-view-grid', {
    attrs: {
      "bottomSheet": false
    },
    scopedSlots: _vm._u([{
      key: "details",
      fn: function fn() {
        var _vm$value$owner, _vm$value$keeper, _vm$value$newKeeper;

        return [_c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("common.nouns.vehicle")))]), _vm.vehicle.loading ? _c('v-skeleton-loader', {
          staticClass: "mx-auto",
          attrs: {
            "type": "paragraph"
          }
        }) : _vm.vehicle ? _c('v-card', {
          attrs: {
            "flat": "",
            "outlined": ""
          }
        }, [_c('refs-vehicle', {
          attrs: {
            "item": _vm.vehicle
          },
          on: {
            "openDetail": _vm.goToVehicle
          }
        })], 1) : _vm._e()], 1), _c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("common.nouns.parties")))]), _c('v-card', {
          staticClass: "mb-2",
          attrs: {
            "flat": "",
            "outlined": ""
          }
        }, [(_vm$value$owner = _vm.value.owner) !== null && _vm$value$owner !== void 0 && _vm$value$owner.companyName ? _c('v-card-text', [_c('div', [_c('b', [_vm._v(_vm._s(_vm.value.owner.companyName))])]), _c('div', [_vm._v(" " + _vm._s(_vm.value.owner.email) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.value.owner.phone) + " ")])]) : _c('v-card-text', [_c('v-btn', {
          attrs: {
            "flat": "",
            "text": ""
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v(" Neuen Besitzer hinzufügen ")], 1)], 1)], 1), _c('v-card', {
          staticClass: "mb-2",
          attrs: {
            "flat": "",
            "outlined": ""
          }
        }, [(_vm$value$keeper = _vm.value.keeper) !== null && _vm$value$keeper !== void 0 && _vm$value$keeper.companyName ? _c('v-card-text', [_c('div', [_c('b', [_vm._v(_vm._s(_vm.value.keeper.companyName))])]), _c('div', [_vm._v(" " + _vm._s(_vm.value.keeper.email) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.value.keeper.phone) + " ")])]) : _c('v-card-text', [_c('v-btn', {
          attrs: {
            "flat": "",
            "text": ""
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v(" Halter hinzufügen ")], 1)], 1)], 1), _c('v-card', {
          staticClass: "mb-2",
          attrs: {
            "flat": "",
            "outlined": ""
          }
        }, [(_vm$value$newKeeper = _vm.value.newKeeper) !== null && _vm$value$newKeeper !== void 0 && _vm$value$newKeeper.companyName ? _c('v-card-text', [_c('div', [_c('b', [_vm._v(_vm._s(_vm.value.newKeeper.companyName))])]), _c('div', [_vm._v(" " + _vm._s(_vm.value.newKeeper.email) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.value.newKeeper.phone) + " ")])]) : _c('v-card-text', [_c('v-btn', {
          attrs: {
            "flat": "",
            "text": ""
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v(" Neuen Halter hinzufügen ")], 1)], 1)], 1)], 1), _c('m-detail-table', {
          attrs: {
            "title": "Eigenschaften",
            "i18nPath": "objects.handover",
            "item": _vm.value,
            "showMoreAction": _vm.tableShowMoreAction,
            "config": _vm.detailTableConfig
          },
          on: {
            "actionClicked": function actionClicked($event) {
              return $event.exec();
            }
          },
          scopedSlots: _vm._u([{
            key: "slot.owner",
            fn: function fn() {
              return [_vm.value.owner ? _c('span', [_c('div', [_c('b', [_vm._v(_vm._s(_vm.value.owner.companyName))])]), _c('div', [_vm._v(_vm._s(_vm.value.owner.email))]), _c('div', [_vm._v(_vm._s(_vm.value.owner.phone))])]) : _vm._e()];
            },
            proxy: true
          }, {
            key: "slot.keeper",
            fn: function fn() {
              var _vm$value$keeper$comp;

              return [_vm.value.keeper ? _c('span', [_c('div', [_c('b', [_vm._v(_vm._s((_vm$value$keeper$comp = _vm.value.keeper.companyName) !== null && _vm$value$keeper$comp !== void 0 ? _vm$value$keeper$comp : "EMPTY"))])]), _c('div', [_vm._v(_vm._s(_vm.value.keeper.email))]), _c('div', [_vm._v(_vm._s(_vm.value.keeper.phone))])]) : _vm._e()];
            },
            proxy: true
          }, {
            key: "slot.newKeeper",
            fn: function fn() {
              var _vm$value$newKeeper$c;

              return [_vm.value.newKeeper ? _c('span', [_c('div', [_c('b', [_vm._v(_vm._s((_vm$value$newKeeper$c = _vm.value.newKeeper.companyName) !== null && _vm$value$newKeeper$c !== void 0 ? _vm$value$newKeeper$c : "EMPTY"))])]), _c('div', [_vm._v(_vm._s(_vm.value.newKeeper.email))]), _c('div', [_vm._v(_vm._s(_vm.value.newKeeper.phone))])]) : _vm._e()];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('m-action-list', {
          attrs: {
            "divider": true,
            "actions": _vm.actions
          },
          on: {
            "actionClicked": function actionClicked($event) {
              return $event.exec();
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('debug', [_vm._v("value " + _vm._s(_vm.value))]), _c('m-navigation-list', {
    attrs: {
      "title": "Navigation",
      "actions": _vm.navigation
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      }
    }
  }), _c('m-quick-link-list', {
    attrs: {
      "title": "Schnellzugriffe",
      "actions": _vm.quickLinks
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      }
    }
  }), _c('create-handover-finding-dialog', {
    attrs: {
      "isDialogActive": _vm.createDialog
    },
    on: {
      "close": function close($event) {
        _vm.createDialog = false;
      }
    }
  }), _c('tour-handover-finding-dialog', {
    attrs: {
      "isDialogActive": _vm.tourDialog
    },
    on: {
      "close": function close($event) {
        _vm.tourDialog = false;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }