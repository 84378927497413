












import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import InspectionDetail from "@/components/handover/InspectionDetail.vue";
import MHeader, { IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import { dottedSubString } from "@/lib/utility/string-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Handover, IHandover } from "@/models/handover.entity";
import { IInspection, Inspection } from "@/models/inspection.entity";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { TheLayoutPortal, MHeader, InspectionDetail, LatestEntriesCardEmpty }
})
export default class InspectionDetailView extends mixins(Vue, PermissionMixin) {
  title = this.$t("inspection.title");

  isLoading = false;

  inspection: IInspection | null = null;

  handover: IHandover | null = null;

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.handover || !this.inspection) {
      return breadcrumbs;
    }

    breadcrumbs.push({
      text: this.$t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.handover.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.handover.title, 30);
    } else {
      handoverTitle = `#${this.handover.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: false,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    breadcrumbs.push({
      text: this.$t("inspection.title"),
      exact: true,
      disabled: false,
      to: {
        name: "InspectionTableView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    breadcrumbs.push({
      text: dottedSubString(this.inspection.title, 30),
      exact: true,
      disabled: true,
      to: {
        name: "InspectionDetailView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id,
          inspectionId: this.inspection.id
        }
      }
    });

    return breadcrumbs;
  }

  async fetchInspection() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;
    const inspectionId = this.$route.params.inspectionId;

    const inspection = await new Inspection({
      partnerId: partnerId,
      handoverId: handoverId,
      id: inspectionId
    }).fetch();

    this.inspection = inspection;
  }

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;
  }

  async mounted() {
    try {
      this.isLoading = true;
      await this.fetchHandover();
      await Promise.all([this.fetchInspection()]);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
