





























import { requiredRule } from "@/lib/rules/requiredRule";
import { IAddress } from "@/models/address.entity";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class AddressForm extends Vue {
  @Prop({ default: "" })
  subheader!: string;

  @Prop()
  value!: IAddress;

  @Prop({ default: true })
  isValid!: boolean;

  panel: number[] = [];

  get localIsValid() {
    return this.isValid;
  }

  set localIsValid(value: boolean) {
    this.$emit("update:isValid", value);
  }

  get requiredRule() {
    return [requiredRule()];
  }
}
